import React from 'react'
import Helmet from 'react-helmet'
import $ from 'jquery'
import { graphql } from 'gatsby'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

import FramelessLayout from '../components/layouts/FramelessLayout'
import Header from '../components/shared/Header'
import Footer from '../components/shared/Footer'

const emailjs = require('emailjs-com')

export default class ContactPage extends React.Component {
  componentDidMount = async () => {
    await import('magnific-popup')
    await import('jquery-validation')

    // set up popups
    const $win = $(window)
    let $scrollPosition = null
    $('.js-popup').magnificPopup({
      type: 'inline',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      fixedContentPos: true,
      closeBtnInside: true,
      callbacks: {
        open() {
          $scrollPosition = $win.scrollTop()
          $('.wrapper').css({ top: -$scrollPosition })
          $('body').addClass('fixed')
        },
        close() {
          $('body').removeClass('fixed')
          $('.wrapper').css({ top: 0 })
          $('html, body').scrollTop($scrollPosition)
          $scrollPosition = null
        },
      },
    })

    // set up map
    $('.map').each((i, elem) => {
      const data = JSON.parse(elem.dataset.map.replace(/\'/g, '"'))

      const map = new google.maps.Map(elem, {
        styles: [
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#e9e9e9' }, { lightness: 17 }],
          },
          {
            featureType: 'landscape',
            elementType: 'geometry',
            stylers: [{ color: '#f5f5f5' }, { lightness: 20 }],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.fill',
            stylers: [{ color: '#ffffff' }, { lightness: 17 }],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }],
          },
          {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [{ color: '#ffffff' }, { lightness: 18 }],
          },
          {
            featureType: 'road.local',
            elementType: 'geometry',
            stylers: [{ color: '#ffffff' }, { lightness: 16 }],
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [{ color: '#f5f5f5' }, { lightness: 21 }],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ color: '#dedede' }, { lightness: 21 }],
          },
          {
            elementType: 'labels.text.stroke',
            stylers: [
              { visibility: 'on' },
              { color: '#ffffff' },
              { lightness: 16 },
            ],
          },
          {
            elementType: 'labels.text.fill',
            stylers: [
              { saturation: 36 },
              { color: '#333333' },
              { lightness: 40 },
            ],
          },
          { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
          {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [{ color: '#f2f2f2' }, { lightness: 19 }],
          },
          {
            featureType: 'administrative',
            elementType: 'geometry.fill',
            stylers: [{ color: '#fefefe' }, { lightness: 20 }],
          },
          {
            featureType: 'administrative',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }],
          },
        ],
        disableDefaultUI: true,
        ...data.map,
      })

      new google.maps.Marker({
        map: map,
        ...data.marker,
      })
    })

    // set up validation
    $('.form-client').validate({
      highlight: function(element) {
        $(element)
          .parents('.form__controls')
          .addClass('error-item')
          .removeClass('valid-item')
      },
      unhighlight: function(element) {
        $(element)
          .parents('.form__controls')
          .addClass('valid-item')
          .removeClass('error-item')
      },
      submitHandler: async e => {
        const templateParams = {
          type: 'client',
          email: $(e)
            .find("[name ='field-email']")
            .first()
            .val(),
          name: $(e)
            .find("[name ='field-name']")
            .first()
            .val(),
          phone: $(e)
            .find("[name ='field-phone-number']")
            .first()
            .val(),
          company: $(e)
            .find("[name ='field-company']")
            .first()
            .val(),
          support_area: $(e)
            .find("[name ='field-radios']:checked")
            .first()
            .val(),
          what_it_will_be: $(e)
            .find("[name ='field-radios2']:checked")
            .first()
            .val(),
          message: $(e)
            .find("[name ='field-message']")
            .first()
            .val(),
        }

        $('.mfp-close').click()
        toast('The message is being sent', {
          className: 'toast-black-background',
          bodyClassName: 'toast-black-background',
          progressClassName: 'toast-black-progress',
        })

        await emailjs
          .send(
            'sendgrid',
            'boldbreed_client_form',
            templateParams,
            'user_CHTxM1KL5iFvUK956ncui'
          )
          .then(
            () => {
              toast.success('Your message has been delivered')
            },
            () => {
              toast.success('There was an error')
            }
          )
      },
      success: 'valid',
    })

    $('.form-artist').validate({
      highlight: function(element) {
        $(element)
          .parents('.form__controls')
          .addClass('error-item')
          .removeClass('valid-item')
      },
      unhighlight: function(element) {
        $(element)
          .parents('.form__controls')
          .addClass('valid-item')
          .removeClass('error-item')
      },
      submitHandler: async e => {
        const templateParams = {
          type: 'artist',
          email: $(e)
            .find("[name ='field-email2']")
            .first()
            .val(),
          name: $(e)
            .find("[name ='field-name2']")
            .first()
            .val(),
          phone: $(e)
            .find("[name ='field-phone-number2']")
            .first()
            .val(),
          support_area: $(e)
            .find("[name ='field-radios3']:checked")
            .first()
            .val(),
          message: $(e)
            .find("[name ='field-message2']")
            .first()
            .val(),
        }

        $('.mfp-close').click()
        toast('The message is being sent', {
          className: 'toast-black-background',
          bodyClassName: 'toast-black-background',
          progressClassName: 'toast-black-progress',
        })

        await emailjs
          .send(
            'sendgrid',
            'boldbreed_client_form',
            templateParams,
            'user_CHTxM1KL5iFvUK956ncui'
          )
          .then(
            function(response) {
              toast.success('Your message has been delivered')
            },
            function(error) {
              toast.success('There was an error')
            }
          )
      },
      success: 'valid',
    })
  }

  toggleAccordion = () => {
    const acc = $('.js-accordion-trigger')
    if (acc.parent().hasClass('expanded')) {
      acc
        .next()
        .slideUp()
        .parent()
        .removeClass('expanded')
    } else {
      acc
        .next()
        .slideToggle()
        .parent()
        .addClass('expanded')
        .siblings()
        .removeClass('expanded')
        .children('.accordion-body')
        .slideUp()
    }
  }

  render() {
    const { data } = this.props
    const seo = {
      title: data.wordpressPage.acf.seo_meta_title || "",
      description: data.wordpressPage.acf.seo_meta_description || "",
      robotsIndex: data.wordpressPage.acf.seo_index ? 'index' : 'noindex',
      robotsFollow: data.wordpressPage.acf.seo_follow ? 'follow' : 'nofollow',
    }
    const { acf: page } = data.wordpressPage

    return (
      <FramelessLayout>
        <Helmet
          title={seo.title}
          bodyAttributes={{
            class: 'has-logo-left',
          }}
        >
          <html lang="en"/>
                    { seo.description !== "" &&
          <meta name="description" content={seo.description} />
          }
          <meta name="robots" content={seo.robotsIndex + ", " + seo.robotsFollow} />
          <link rel='canonical' href='https://boldbreed.de/contact/' />
        </Helmet>

        <ToastContainer />

        <div className='wrapper'>
          <div className='wrapper__lines'></div>

          <div className='wrapper__inner'>
            <Header path={this.props.path} />

            <section className='section-map'>
              <header className='section__head contact'>
                <h2>CONTACT</h2>
              </header>
              <div
                className='map'
                data-map="{ 'map' : {'center': {'lat': 52.5277938, 'lng': 13.3945088}, 'zoom': 18}, 'marker': { 'position': {'lat': 52.5277938, 'lng': 13.3945088 }, 'icon': '/assets/images/marker.svg' } }"
              ></div>
            </section>

            <section className='section-info'>
              <header className='section__head contact'>
                <h2>CONTACT</h2>
              </header>

              <div className='section__content contact'>
                <div className='section__cols'>
                  <div className='section__col'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: page.block_contact_info,
                      }}
                    />

                    <br />

                    <div className='accordion'>
                      <div className='accordion__section'>
                        <div
                          className='accordion__head js-accordion-trigger'
                          onClick={this.toggleAccordion}
                        >
                          <p>
                            <strong>{page.email_list_heading}</strong>
                          </p>
                        </div>
                        <div
                          className='accordion__body'
                          dangerouslySetInnerHTML={{ __html: page.email_list }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='section__col section__col--border'>
                    <h3>{page.block_1_heading}</h3>

                    <p>{page.block_1_text}</p>

                    <a
                      href='#popup-form1'
                      className='btn-arrow btn-arrow--small js-popup'
                    >
                      {page.block_1_cta}
                      <span>
                        <svg
                          width='58'
                          height='40'
                          viewBox='0 0 58 40'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M57.7374 1.35961C57.7374 1.21492 57.7149 1.07023 57.6675 0.928025C57.6601 0.910562 57.6476 0.895594 57.6401 0.878131C57.6201 0.828237 57.5927 0.785827 57.5678 0.740923C57.5154 0.641135 57.4555 0.548832 57.3831 0.466504C57.3457 0.424094 57.3083 0.389171 57.2659 0.351746C57.1811 0.279401 57.0913 0.219527 56.9915 0.167139C56.9466 0.144687 56.9042 0.117246 56.8543 0.0972912C56.7021 0.0399127 56.5424 0 56.3703 0L1.62882 0.00249471C1.45918 0.00249471 1.29952 0.04241 1.14485 0.0997859C1.09495 0.117249 1.05254 0.14469 1.00764 0.16964C0.910349 0.219534 0.818039 0.279411 0.735718 0.351756C0.693307 0.389177 0.65339 0.426599 0.615972 0.469005C0.543626 0.551333 0.483753 0.64114 0.433855 0.740928C0.408909 0.788328 0.378972 0.830735 0.36151 0.880632C0.354026 0.898095 0.341552 0.913063 0.336563 0.930526C0.289163 1.07273 0.266708 1.21742 0.266708 1.36211C0.264213 1.36211 0.261719 1.3646 0.261719 1.3671V38.6333C0.261719 39.3892 0.872922 40.0004 1.6288 40.0004H56.3754C57.1288 40.0004 57.7425 39.3892 57.7425 38.6333V1.3671C57.74 1.3646 57.7375 1.36211 57.7375 1.35961L57.7374 1.35961ZM2.99336 37.2662V4.12877L28.17 23.3654C28.417 23.5525 28.7064 23.6448 29.0008 23.6448C29.2927 23.6448 29.5845 23.5525 29.8315 23.3654L55.0082 4.12877V37.2662H2.99336ZM29.0009 20.5643L5.66545 2.73461H52.3364L29.0009 20.5643Z'
                            fill='#26ED5D'
                          />
                        </svg>
                      </span>
                    </a>

                    <div id='popup-form1' className='mfp-hide popup'>
                      <div className='popup__content'>
                        <div className='form form-validated form-client'>
                          <form
                            method='post'
                            onSubmit={e => e.preventDefault()}
                          >
                            <div className='form__head'>
                              <h3>{page.block_1_form_title}</h3>
                            </div>

                            <div className='form__body'>
                              <div className='form__row'>
                                <label
                                  htmlFor='field-name'
                                  className='form__label sr-only'
                                >
                                  name
                                </label>

                                <div className='form__controls'>
                                  <input
                                    type='text'
                                    className='field'
                                    name='field-name'
                                    id='field-name'
                                    placeholder='name*'
                                    required
                                  />
                                </div>
                              </div>

                              <div className='form__row'>
                                <label
                                  htmlFor='field-email'
                                  className='form__label sr-only'
                                >
                                  email
                                </label>

                                <div className='form__controls'>
                                  <input
                                    type='email'
                                    className='field'
                                    name='field-email'
                                    id='field-email'
                                    placeholder='e-mail*'
                                    required
                                  />
                                </div>
                              </div>

                              <div className='form__row'>
                                <label
                                  htmlFor='field-phone-number'
                                  className='form__label sr-only'
                                >
                                  phone-number
                                </label>

                                <div className='form__controls'>
                                  <input
                                    type='text'
                                    className='field'
                                    name='field-phone-number'
                                    id='field-phone-number'
                                    placeholder='phone number (to get back to you)*'
                                    required
                                  />
                                </div>
                              </div>

                              <div className='form__row'>
                                <label
                                  htmlFor='field-company'
                                  className='form__label sr-only'
                                >
                                  company
                                </label>

                                <div className='form__controls'>
                                  <input
                                    type='text'
                                    className='field'
                                    name='field-company'
                                    id='field-company'
                                    placeholder='company'
                                  />
                                </div>
                              </div>

                              <div className='form__row'>
                                <label
                                  htmlFor='field-radios'
                                  className='form__label'
                                >
                                  in which area do you need support?
                                </label>

                                <ul className='list-radios'>
                                  <li>
                                    <div className='radio'>
                                      <input
                                        type='radio'
                                        name='field-radios'
                                        value='audio'
                                        id='field-audio'
                                      />

                                      <label htmlFor='field-audio'>audio</label>
                                    </div>
                                  </li>

                                  <li>
                                    <div className='radio'>
                                      <input
                                        type='radio'
                                        name='field-radios'
                                        value='video'
                                        id='field-video'
                                      />

                                      <label htmlFor='field-video'>video</label>
                                    </div>
                                  </li>

                                  <li>
                                    <div className='radio'>
                                      <input
                                        type='radio'
                                        name='field-radios'
                                        value='both'
                                        id='field-both'
                                      />

                                      <label htmlFor='field-both'>both</label>
                                    </div>
                                  </li>
                                </ul>
                              </div>

                              <div className='form__row'>
                                <label
                                  htmlFor='field-radios2'
                                  className='form__label'
                                >
                                  what will it be?
                                </label>

                                <ul className='list-radios'>
                                  <li>
                                    <div className='radio'>
                                      <input
                                        type='radio'
                                        name='field-radios2'
                                        value='on set service'
                                        id='field-on-set-service'
                                      />

                                      <label htmlFor='field-on-set-service'>
                                        on set service
                                      </label>
                                    </div>
                                  </li>

                                  <li>
                                    <div className='radio'>
                                      <input
                                        type='radio'
                                        name='field-radios2'
                                        value='post production'
                                        id='field-post-production'
                                      />

                                      <label htmlFor='field-post-production'>
                                        post production
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                              </div>

                              <div className='form__row'>
                                <label
                                  htmlFor='field-message'
                                  className='form__label sr-only'
                                >
                                  drop a line explaining your project ...
                                </label>

                                <div className='form__controls'>
                                  <textarea
                                    className='textarea'
                                    name='field-message'
                                    id='field-message'
                                    placeholder='drop a line with some project details, if necessary...'
                                  ></textarea>
                                </div>
                              </div>
                            </div>

                            <div className='form__actions'>
                              <button
                                type='submit'
                                className='btn-arrow btn-arrow--small form__btn'
                              >
                                Send
                                <span>
                                  <svg
                                    width='58'
                                    height='40'
                                    viewBox='0 0 58 40'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M57.7374 1.35961C57.7374 1.21492 57.7149 1.07023 57.6675 0.928025C57.6601 0.910562 57.6476 0.895594 57.6401 0.878131C57.6201 0.828237 57.5927 0.785827 57.5678 0.740923C57.5154 0.641135 57.4555 0.548832 57.3831 0.466504C57.3457 0.424094 57.3083 0.389171 57.2659 0.351746C57.1811 0.279401 57.0913 0.219527 56.9915 0.167139C56.9466 0.144687 56.9042 0.117246 56.8543 0.0972912C56.7021 0.0399127 56.5424 0 56.3703 0L1.62882 0.00249471C1.45918 0.00249471 1.29952 0.04241 1.14485 0.0997859C1.09495 0.117249 1.05254 0.14469 1.00764 0.16964C0.910349 0.219534 0.818039 0.279411 0.735718 0.351756C0.693307 0.389177 0.65339 0.426599 0.615972 0.469005C0.543626 0.551333 0.483753 0.64114 0.433855 0.740928C0.408909 0.788328 0.378972 0.830735 0.36151 0.880632C0.354026 0.898095 0.341552 0.913063 0.336563 0.930526C0.289163 1.07273 0.266708 1.21742 0.266708 1.36211C0.264213 1.36211 0.261719 1.3646 0.261719 1.3671V38.6333C0.261719 39.3892 0.872922 40.0004 1.6288 40.0004H56.3754C57.1288 40.0004 57.7425 39.3892 57.7425 38.6333V1.3671C57.74 1.3646 57.7375 1.36211 57.7375 1.35961L57.7374 1.35961ZM2.99336 37.2662V4.12877L28.17 23.3654C28.417 23.5525 28.7064 23.6448 29.0008 23.6448C29.2927 23.6448 29.5845 23.5525 29.8315 23.3654L55.0082 4.12877V37.2662H2.99336ZM29.0009 20.5643L5.66545 2.73461H52.3364L29.0009 20.5643Z'
                                      fill='#26ED5D'
                                    />
                                  </svg>
                                </span>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='section__col section__col--border'>
                    <h3>{page.block_2_heading}</h3>

                    <p>{page.block_2_text}</p>

                    <a
                      href='#popup-form2'
                      className='btn-arrow btn-arrow--small js-popup'
                    >
                      {page.block_2_cta}
                      <span>
                        <svg
                          width='58'
                          height='40'
                          viewBox='0 0 58 40'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M57.7374 1.35961C57.7374 1.21492 57.7149 1.07023 57.6675 0.928025C57.6601 0.910562 57.6476 0.895594 57.6401 0.878131C57.6201 0.828237 57.5927 0.785827 57.5678 0.740923C57.5154 0.641135 57.4555 0.548832 57.3831 0.466504C57.3457 0.424094 57.3083 0.389171 57.2659 0.351746C57.1811 0.279401 57.0913 0.219527 56.9915 0.167139C56.9466 0.144687 56.9042 0.117246 56.8543 0.0972912C56.7021 0.0399127 56.5424 0 56.3703 0L1.62882 0.00249471C1.45918 0.00249471 1.29952 0.04241 1.14485 0.0997859C1.09495 0.117249 1.05254 0.14469 1.00764 0.16964C0.910349 0.219534 0.818039 0.279411 0.735718 0.351756C0.693307 0.389177 0.65339 0.426599 0.615972 0.469005C0.543626 0.551333 0.483753 0.64114 0.433855 0.740928C0.408909 0.788328 0.378972 0.830735 0.36151 0.880632C0.354026 0.898095 0.341552 0.913063 0.336563 0.930526C0.289163 1.07273 0.266708 1.21742 0.266708 1.36211C0.264213 1.36211 0.261719 1.3646 0.261719 1.3671V38.6333C0.261719 39.3892 0.872922 40.0004 1.6288 40.0004H56.3754C57.1288 40.0004 57.7425 39.3892 57.7425 38.6333V1.3671C57.74 1.3646 57.7375 1.36211 57.7375 1.35961L57.7374 1.35961ZM2.99336 37.2662V4.12877L28.17 23.3654C28.417 23.5525 28.7064 23.6448 29.0008 23.6448C29.2927 23.6448 29.5845 23.5525 29.8315 23.3654L55.0082 4.12877V37.2662H2.99336ZM29.0009 20.5643L5.66545 2.73461H52.3364L29.0009 20.5643Z'
                            fill='#26ED5D'
                          />
                        </svg>
                      </span>
                    </a>

                    <div id='popup-form2' className='mfp-hide popup'>
                      <div className='popup__content'>
                        <div className='form form-validated form-artist'>
                          <form
                            method='post'
                            onSubmit={e => e.preventDefault()}
                          >
                            <div className='form__head'>
                              <h3>{page.block_2_form_title}</h3>
                            </div>

                            <div className='form__body'>
                              <div className='form__row'>
                                <label
                                  htmlFor='field-name2'
                                  className='form__label sr-only'
                                >
                                  name
                                </label>

                                <div className='form__controls'>
                                  <input
                                    type='text'
                                    className='field'
                                    name='field-name2'
                                    id='field-name2'
                                    placeholder='name*'
                                    required
                                  />
                                </div>
                              </div>

                              <div className='form__row'>
                                <label
                                  htmlFor='field-email2'
                                  className='form__label sr-only'
                                >
                                  email
                                </label>

                                <div className='form__controls'>
                                  <input
                                    type='email'
                                    className='field'
                                    name='field-email2'
                                    id='field-email2'
                                    placeholder='e-mail*'
                                    required
                                  />
                                </div>
                              </div>

                              <div className='form__row'>
                                <label
                                  htmlFor='field-phone-number2'
                                  className='form__label sr-only'
                                >
                                  phone-number
                                </label>

                                <div className='form__controls'>
                                  <input
                                    type='text'
                                    className='field'
                                    name='field-phone-number2'
                                    id='field-phone-number2'
                                    placeholder='phone number (to get back to you)*'
                                    required
                                  />
                                </div>
                              </div>

                              <div className='form__row'>
                                <label
                                  htmlFor='field-radios3'
                                  className='form__label'
                                >
                                  in which area do you need support?
                                </label>

                                <ul className='list-radios'>
                                  <li>
                                    <div className='radio'>
                                      <input
                                        type='radio'
                                        name='field-radios3'
                                        id='field-audio3'
                                        value='audio'
                                      />

                                      <label htmlFor='field-audio3'>
                                        audio
                                      </label>
                                    </div>
                                  </li>

                                  <li>
                                    <div className='radio'>
                                      <input
                                        type='radio'
                                        name='field-radios3'
                                        id='field-video3'
                                        value='video'
                                      />

                                      <label htmlFor='field-video3'>
                                        video
                                      </label>
                                    </div>
                                  </li>

                                  <li>
                                    <div className='radio'>
                                      <input
                                        type='radio'
                                        name='field-radios3'
                                        id='field-both3'
                                        value='both'
                                      />

                                      <label htmlFor='field-both3'>both</label>
                                    </div>
                                  </li>
                                </ul>
                              </div>

                              <div className='form__row'>
                                <label
                                  htmlFor='field-message2'
                                  className='form__label sr-only'
                                >
                                  drop a line explaining your project ...
                                </label>

                                <div className='form__controls'>
                                  <textarea
                                    className='textarea'
                                    name='field-message2'
                                    id='field-message2'
                                    placeholder='tell us a bit about your profession and yourself...'
                                  ></textarea>
                                </div>
                              </div>
                            </div>

                            <div className='form__actions'>
                              <button
                                type='submit'
                                className='btn-arrow btn-arrow--small form__btn'
                              >
                                Send
                                <span>
                                  <svg
                                    width='58'
                                    height='40'
                                    viewBox='0 0 58 40'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M57.7374 1.35961C57.7374 1.21492 57.7149 1.07023 57.6675 0.928025C57.6601 0.910562 57.6476 0.895594 57.6401 0.878131C57.6201 0.828237 57.5927 0.785827 57.5678 0.740923C57.5154 0.641135 57.4555 0.548832 57.3831 0.466504C57.3457 0.424094 57.3083 0.389171 57.2659 0.351746C57.1811 0.279401 57.0913 0.219527 56.9915 0.167139C56.9466 0.144687 56.9042 0.117246 56.8543 0.0972912C56.7021 0.0399127 56.5424 0 56.3703 0L1.62882 0.00249471C1.45918 0.00249471 1.29952 0.04241 1.14485 0.0997859C1.09495 0.117249 1.05254 0.14469 1.00764 0.16964C0.910349 0.219534 0.818039 0.279411 0.735718 0.351756C0.693307 0.389177 0.65339 0.426599 0.615972 0.469005C0.543626 0.551333 0.483753 0.64114 0.433855 0.740928C0.408909 0.788328 0.378972 0.830735 0.36151 0.880632C0.354026 0.898095 0.341552 0.913063 0.336563 0.930526C0.289163 1.07273 0.266708 1.21742 0.266708 1.36211C0.264213 1.36211 0.261719 1.3646 0.261719 1.3671V38.6333C0.261719 39.3892 0.872922 40.0004 1.6288 40.0004H56.3754C57.1288 40.0004 57.7425 39.3892 57.7425 38.6333V1.3671C57.74 1.3646 57.7375 1.36211 57.7375 1.35961L57.7374 1.35961ZM2.99336 37.2662V4.12877L28.17 23.3654C28.417 23.5525 28.7064 23.6448 29.0008 23.6448C29.2927 23.6448 29.5845 23.5525 29.8315 23.3654L55.0082 4.12877V37.2662H2.99336ZM29.0009 20.5643L5.66545 2.73461H52.3364L29.0009 20.5643Z'
                                      fill='#26ED5D'
                                    />
                                  </svg>
                                </span>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <Footer />
          </div>
        </div>
      </FramelessLayout>
    )
  }
}

export const contactQuery = graphql`
  query ContactPageQuery {
    wordpressPage(title: { eq: "Contact" }) {
      acf {
        block_1_heading
        block_1_text
        block_1_cta
        block_1_form_title
        block_2_heading
        block_2_text
        block_2_cta
        block_2_form_title
        email_list_heading
        email_list
        block_contact_info
        seo_meta_title
        seo_meta_description
        seo_index
        seo_follow
      }
    }
  }
`
